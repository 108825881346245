import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Hero from '../components/hero'
import Layout from '../components/layout'
  
import ToggleButton from '../components/toggle-button'


import ArticleListing from '../components/article-listing'


import Img from 'gatsby-image'



import MetadataGenerator from '../components/metadata-generator'

import PopularSolutions from '../components/popular-solutions'

import Tile from '../components/tile'

import ContentTile from '../components/content-tile'


import BoxContacts from '../components/box-contacts'

import tileStyles from '../components/tile.module.scss'

import {sectionHeading} from '../components/section-heading.module.scss'

import Collapsible from 'react-collapsible';

//cannot have relative links for metadata 
import { Location } from '@reach/router'

import { section, black, productSelection } from '../components/section-style.module.scss'





import ProductSelector from '../components/ProductSelector'

class RootIndex extends React.Component {
//   constructor() {
//     super()
//     this.state = {
//       checked: false
//     }
//     this.handleButtonClick = this.handleButtonClick.bind(this)
//  
// 
//   }
// 
//   
//   handleButtonClick() {
//     console.log("Clicked")
//   }
  
  
  
  // this.handleCheckboxChange = this.handleCheckboxChange.bind(this)

  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteUrl = get(this, 'props.data.site.siteMetadata.siteUrl')
    const pages = get(this, 'props.data.allContentfulSupportSitePage.edges')
    const articles = get(this, 'props.data.allContentfulSupportSiteArticle.edges')
    const supportProducts = get(this, 'props.data.allContentfulSupportSiteProduct.edges')
    const topSolutions = get(this, 'props.data.contentfulSupportSiteContentTile')
    
    const allErrorArticles = get(this, 'props.data.articleTest')
    //const contentTiles = get(this, 'props.data.allContentfulContentTile.edges')

    const contactSections = get(this, 'props.data.allContentfulSupportSiteContactSection.edges')

    const videos = get(this, 'props.data.allContentfulSupportSiteVideo.edges')
    const updateTables = get(this, 'props.data.allContentfulSupportSiteProductUpdateTable.edges')
    const homepageTiles = get(this, 'props.data.contentfulSupportSiteOverview');
    
    const homescreenCheck = get(this,'props.data.homescreenPageCheck.edges')
    const supportChangesCheck = get(this,'props.data.changesPageCheck.edges')
    
//     const [activeIndex, setActiveIndex] = useState(null);
// 
// 
//     const handleItemClick = (index) => {
//       setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
//      };
     
   // console.log(homescreenCheck)
    
   // console.log(supportChangesCheck)
//     
//     if (homescreenCheck && homescreenCheck.length) {
//       console.log('homescreen page found')
//     }
//     if (supportChangesCheck && supportChangesCheck.length) {
//       console.log('supportChangesCheck page found')
//     }
// if(supportChangesCheck && supportChangesCheck.length == 0) {
//   console.log('no support changes page');
// }
   // console.log(topSolutions)


    const metaDescription = "Welcome to YouView support. Find out how to set up and use YouView and browse popular topics to help you with all of your YouView products."


    var homepageTilesObject = ''

    var homepageTilesOutput = ''

    if(this.props.data.contentfulSupportSiteOverview) {
      homepageTilesObject = this.props.data.contentfulSupportSiteOverview.homepageContentTiles;

      homepageTilesOutput = this.props.data.contentfulSupportSiteOverview.homepageContentTiles.map((node, i) => (
                 <ContentTile key={node.id + i} fields={node} tilePages={pages} tileArticles={articles} iterator={i}/>
                  ))
      //console.log(homepageTilesObject)
    }


    const userGuides = get(this, 'props.data.allContentfulUserGuide.edges')

    var articleArray = [];
    var solutionsArray = [];

    // articles.map((article, i) => {
    //       return articleArray.push({article})
    //     });
    if(topSolutions !== null && topSolutions.hasOwnProperty('tileArticles') ){
      //console.log(topSolutions.tileArticles);
      
      topSolutions.tileArticles.map((article, i) => {
        //console.log(article)
        
        let solutionTitle = article.title
        //let solutionSlug = 
        //if()
      //   return solutionsArray.push({
      //     id:article.node.id,
      //     title:article.node.title,
      //     slug:article.node.slug,
      //     date:article.node.updatedAt,
      //     topicSlug:(article.node.pageTopic ? article.node.pageTopic.slug : null),
      //     productSlug:(article.node.pageProduct ? article.node.pageProduct.slug : null),
      //     description:(article.node.description ? article.node.description : null),
      //     articleBody:(article.node.body ? article.node.body.json : null)
      // });
      });
    }
    
    var topSolutionsObject = '';
    pages.map((article, i) => {
          return articleArray.push({
            id:article.node.id,
            title:article.node.title,
            slug:article.node.slug,
            date:article.node.updatedAt,
            topicSlug:(article.node.pageTopic ? article.node.pageTopic.slug : null),
            productSlug:(article.node.pageProduct ? article.node.pageProduct.slug : null),
            description:(article.node.description ? article.node.description : null),
            articleBody:(article.node.body ? article.node.body.json : null)
        });
        });
     articles.map((article, i) => {
          return articleArray.push({
            id:article.node.id,
            title:article.node.title,
            slug:article.node.slug,
            date:article.node.updatedAt,
            topicSlug:(article.node.articleCategoryTopic ? article.node.articleCategoryTopic.slug : null),
            productSlug:(article.node.productReference ? article.node.productReference.slug : null),
            description:(article.node.description ? article.node.description : null),
            articleBody:(article.node.articleBody ? article.node.articleBody : null)
        });
      });
articleArray.sort(function(a,b){
  // Turn your strings into dates, and then subtract them
  // to get a value that is either negative, positive, or zero.
  //return new Date(b.date) - new Date(a.date);
    return a.date - b.date;

});

articleArray.reverse();
      

var excludedSlug = 'mobile-app';



    return (

      <Layout alertslocation={this.props.location} title={siteTitle}>
        <MetadataGenerator 
            title={siteTitle} 
            siteTitle={siteTitle} 
            description={metaDescription} 
            urlBase={siteUrl}
            url={this.props.location.href} />

          
        <section className={[section, black, productSelection].join(' ')}>
          <div className="container-fluid no-padding">
            <div className="lead-section text-center">
              <h2 className={sectionHeading}>Select your product</h2>
            </div>

          </div>
<ProductSelector />

        </section>
        <section className={section}>
        <div className={"container"}>
          <div className={"row"}>
            <div className={"lead-section text-center"}>
             <h2 className={sectionHeading}>Popular Solutions</h2>
            </div>
          </div>
        </div>
        
        <PopularSolutions articles={topSolutions}/>
        </section>
      </Layout>

    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    
  #start Page query
  allContentfulSupportSitePage(
    filter: {
      slug: {ne: "placeholder"}
    }
  ) {
    edges {
      node {
        title
        slug
        id
        createdAt (formatString: "X")
        updatedAt (formatString: "X")
        pageTopic {
          ... on ContentfulSupportSiteTopic {
            slug
          }
        }
        pageProduct {
          ... on ContentfulSupportSiteProduct {
            slug
          }
        }
        description {
          childMarkdownRemark {
            html
          }
        }
        body {
          json
        }
      }
    }
  }
  #end Page query

    contentfulSupportSiteOverview(title:{eq:"Overview"}) {
      id
    title
    homepageContentTiles {
      ... on ContentfulSupportSiteContentTile {
        id
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        topic {
          title
          slug
        }
        url
        icon {
          file {
            url
            details {
              image {
                width
                height
              }
            }
          }
        }
        tileArticles {
          ... on ContentfulSupportSiteArticle {
            title
            slug
            articleCategoryTopic {
              slug
            }
            productReference {
              slug
            }
          }
          ... on ContentfulSupportSitePage {
            title
            slug
            pageTopic {
              slug
            }
            pageProduct {
              slug
            }
          }
        }
      }
    }
  }
  contentfulSupportSiteContentTile(title: {glob: "*Solution*"}) {
    title
    contentful_id
    id
    tileArticles {
      ... on ContentfulSupportSiteArticle {
        __typename
        id
        slug
        title
        articleBody {
          content {
            content {
              content {
                content {
                  content {
                    value
                  }
                  value
                }
                value
              }
              value
            }
          }
          json
        }
        description {
          description
        }
        articleCategoryTopic {
          slug
        }
        productReference {
          slug
        }
      }
      ... on ContentfulSupportSitePage {
        __typename
        id
        slug
        title
        description {
          childMarkdownRemark {
            id
          }
          description
        }
        body {
          content {
            content {
              value
            }
          }
        }
      }
    }
  }

    allContentfulSupportSiteArticle(sort: { fields: [updatedAt], order: DESC }) {
      edges {
        node {
          title
          slug
          id
          createdAt (formatString: "X")
          updatedAt (formatString: "X")
          description {
            childMarkdownRemark {
              html
            }
          }
             productReference {
            ... on ContentfulSupportSiteProduct {
              title
              slug
            }
          }
             articleCategoryTopic {
            ... on ContentfulSupportSiteTopic {
              title
              slug
            }
          }
        }
      }
    }
    articleTest: allContentfulSupportSiteArticle(filter: {title: {glob: "*YVM*"}}) {
      nodes {
        __typename
        slug
        title
        description {
          description
        }
        articleBody {
          json
        }
        id
        productReference {
          slug
        }
        articleCategoryTopic {
          slug
        }
      }
    }
 #start product search
 allContentfulSupportSiteProduct(sort: { fields: [createdAt], order: ASC }) {
  edges {
    node {
      title
      slug
      image {
        file {
          details {
            image {
              width
              height
            }
          }
          url
        }
      }
      homepageCategoryOrder {
        ... on ContentfulSupportSiteTopic {
          title
          slug
          icon {
            file {
              url
              details {
                image {
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
  }
}
  #end product search
  homescreenPageCheck:allContentfulSupportSitePage(filter: {slug: {eq: "homescreen"}}) {
    edges {
      node {
        slug
        pageProduct {
          slug
        }
        pageTopic {
          slug
        }
      }
    }
  }
   changesPageCheck: allContentfulSupportSitePage(filter: {slug: {eq: "support-changes"}}) {
     edges {
       node {
         slug
         pageProduct {
           slug
         }
         pageTopic {
           slug
         }
       }
     }
   }
  }
`
