import { useStaticQuery, graphql } from "gatsby"

export const getYouViewProducts = () => {
  const { allContentfulSupportSiteProduct } = useStaticQuery(
	graphql`
query ContentfulProductSearch {
	  allContentfulSupportSiteProduct(filter: {title: {ne: "Mobile App"}, support_site___product_update_table: {}}, sort: {fields: title, order: ASC}) {
		nodes {
		  id
		  title
		  slug
		  image {
			file {
			  url
			  details {
				image {
				  height
				  width
				}
			  }
			}
		  }
		  homepageCategoryOrder {
			title
			slug
			support_site___article {
			  title
			  slug
			  productReference {
				  slug
				}
			}
			icon {
			  file {
				url
				details {
				  image {
					height
					width
				  }
				}
			  }
			  id
			}
		  }
		}
	  }
	}






	`
  )
  //console.log(allContentfulSupportSiteProduct);
  return allContentfulSupportSiteProduct
}