import React from 'react'
import { Link } from 'gatsby'

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import styles from './popular-solutions-listing.module.scss'

function articleCreateLink(slug,category,product){
	
	var articleSlug = '';
	if (product !== undefined) {
		articleSlug = product
	}
	if (category !== undefined) {
		articleSlug = articleSlug+`/`+category
	}
	if (slug !== undefined) {
		articleSlug = articleSlug+`/`+slug
	}
	
	return articleSlug
	
}
//import styles from './tile.module.scss'
class SolutionArticle extends React.Component {

  render() {
  const title = this.props.title
  const description = this.props.description
  const link = this.props.link
  const linkText = this.props.linkText
  
  //console.l0g()
  
  var descriptionText = <p>-</p>
  
  if(description !== null) {
	  // console.log('description!')
	  // console.log(linkText)
	  // console.log('description' in description)
	  if('description' in description && description.description !== null) {
		  descriptionText = <p>{description.description}</p>
	 }
	  else {
		  descriptionText = documentToReactComponents(description)
	  }
	  //descriptionText = description.description
  }
 
	return (
		<article className={styles.popularArticle}>
	  <h3>{title}</h3>
	 {descriptionText}
	 <Link to={link}><span>{linkText}</span></Link>
		</article>
	);
  
}
}

class PopularSolutions extends React.Component {
  render() {
	const tileObject = this.props.articles;
	
	var solutionArticles
	
	if('tileArticles' in tileObject){
		solutionArticles = tileObject.tileArticles
	}
	if('nodes' in tileObject) {
		solutionArticles = tileObject.nodes

	}
var tileOutput = ''
var articleDescription = '';
var articleLink = '';
var linkText = '';
	if(tileObject) {
		tileOutput = solutionArticles.map((
			node, i)  => {
			if('articleBody' in node && node.articleBody !== null) {
				node.articleBody.json.content.length = 1;	
				 articleDescription = node.articleBody.json
			}
			else {
				 articleDescription = node.description
			}
			
			if(node.__typename === 'ContentfulSupportSitePage') {
				articleLink = node.slug
				linkText = node.title
			}
			if(node.__typename === 'ContentfulSupportSiteArticle'){
				articleLink = articleCreateLink(node.slug, node.articleCategoryTopic.slug, node.productReference.slug)
				linkText = 'Read more'
			}
			// console.log(linkText)
			// console.log(articleLink)
			
			//articleLink = 
		return  <SolutionArticle key={node.id} description={articleDescription} title={node.title} link={articleLink}  linkText={linkText} />
		  })
	}

	return (
		 <div className={styles.popularArticleContainer}>
		 {tileOutput}
		 
	</div>
	)
}
}
export default PopularSolutions


