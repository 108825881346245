import React, { useRef, useState } from "react";
import { Link } from 'gatsby'
import { getYouViewProducts } from "../hooks/get-youview-products"
import Collapsible from 'react-collapsible';

//import ProductListing from '../components/product-listing'

import NewTile from '../components/new-tile'

import styles from '../components/product-selector.module.scss'

// 
// class NewProductListing extends React.Component {
//   render() {
// 	const product = this.props.product;
// 	const slug = this.props.product.slug;
// const  {onClick} = this.props
// 	var extraClass = ''
// 	
// 	var imgSrc = product.image.file.url
// 	
// 	if(slug == 'youview-box') {
// 	  imgSrc = '/box-test.svg'
// 	}
// 
// 	var buttonSlug = slug;
// 	var replaceSlug = slug.replace(/-/g, "")
// 	//console.log("replaceSlug is "+replaceSlug)
// 	if(styles[replaceSlug]) {
// 		buttonSlug = styles[replaceSlug]
// 	}
// 
// 	if(this.props.extraClass) {
// 		extraClass = this.props.extraClass
// 	}
// 
// 	//mobile app redirect
// 	// if(slug == 'mobile-app'){
// 	//   return null;
// 	// }
// 
// 
// 
// 
// 
// return (
// 	<a onClick={onClick}>{product.title}</a>
// )
//   }
// }

const NewTileX = ({ tileTitle, tileSlug, articles, productSlug, icon, index }) => {
return (
	<div>
<p>{tileTitle}</p>
<p>{tileTitle}</p>

{icon && <div><img width={icon.file.details.image.width} height={icon.file.details.image.height} src={icon.file.url} /></div>}
{articles && <ul>
{ articles.map((article, index) => {
	let slug = `/` + productSlug + `/` + tileSlug + `/` + article.slug + `/`
	return (<li><Link to={slug}>{article.title}</Link></li>)
})
}
	

</ul>}
{articles && <p><Link to={`/${productSlug}/${tileSlug}/`}>See all</Link></p>}

	</div>
  );
};

const ProductButton = ({ title, index, active, onClick, image, productSlug }) => {
	//console.log('active is '+active)
	
	var imgSrc = image.file.url
	
	if(productSlug == 'youview-box') {
	  imgSrc = '/yv-box-2.svg'
	}
	
	if(productSlug == 'youview-on-sony') {
	  imgSrc = '/youview-tv-new-brand.svg'
	}
	
	
	var className = '';
	if(active) {
		className = styles.active
	}
	
	
	  var glowSrc = '/yv-glow.svg'
	  
	  var shadowSrc= '/shadow-test.svg'
	

return (
	<a className={className} onClick={onClick}><div>
	<img className={ styles.glow }src={glowSrc} />
	<figure className={styles.productImage}>
	<img width={image ? image.file.details.image.width : null} height={image ? image.file.details.image.height : null} src={imgSrc} alt={"Image of "+title}/>
	</figure>
	<img className={styles.shadow} src={shadowSrc} />
<h2>{title}</h2>
</div></a>

  );
};

const AccordionItem = ({ categories, productSlug }) => {
  const contentHeight = useRef();
  return (
	<div className={styles.tileWrapper}>
	<div className={styles.container}>
	
	{categories.map((item, index) => {
		
		if(item.slug !== 'software-updates'){
			if(index <= 5){
				// {people.filter(person => person.hat === hat).map(person => <li>{person.emoji}</li>)}
				//console.log(item.support_site___article.filter(article => article.productReference.slug === productSlug).slice(0, 5));
		return (
			<NewTile key={index} index={index} tileTitle={item.title} articles={item.support_site___article.filter(article => article.productReference.slug === productSlug).slice(0, 5)} productSlug={productSlug} tileSlug={item.slug} icon={item.icon}/>

	)}
	else {
		return (<NewTile key={index} index={index} tileTitle={item.title} productSlug={productSlug} tileSlug={item.slug} />)
	}
}}
	)
}
	</div>
	</div>
  );
};


const ProductSelector = () => {
  const [activeIndex, setActiveIndex] = useState(null);
const { nodes } = getYouViewProducts()

  const handleItemClick = (index) => {
	setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  
  const logOpenColl = (index, id) => {
  // setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  let element = document.getElementById(id);
  element.scrollIntoView({behavior: 'smooth', block: 'start'});
  // console.log(index)
  // console.log(id)
  };

  return (
	<div className={styles.productContainer}>

	  <div className={styles.productSelector}>
	  {nodes.map((item, index) => (
		  
	 <ProductButton title={item.title} key={index}  onClick={() => handleItemClick(index)} active={activeIndex === index} image={item.image} productSlug={item.slug}/>
		  
	  ))}
	  </div>
	  
	  {nodes.map((item, index) => (
	   <Collapsible key={index} trigger={item.title} open={activeIndex === index} contentElementId={item.id} classParentString={styles.productCollapsible} triggerClassName={styles.trigger} triggerOpenedClassName={styles.trigger} easing={'cubic-bezier(.25,.1,.25,1)'} transitionTime={320} onOpen={() => logOpenColl(index,item.id)}>
		 <AccordionItem productSlug={item.slug} categories={item.homepageCategoryOrder}/>
	   </Collapsible>
		))}
		
	</div>
  );
};

//export default Accordion;



export default ProductSelector