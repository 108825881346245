import React from 'react'
import { Link, graphql, useStaticQuery } from "gatsby"


import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import styles from './popular-solutions-listing.module.scss'

function articleCreateLink(slug,category,product){
	
	var articleSlug = '';
	if (product !== undefined) {
		articleSlug = product
	}
	if (category !== undefined) {
		articleSlug = articleSlug+`/`+category
	}
	if (slug !== undefined) {
		articleSlug = articleSlug+`/`+slug
	}
	
	return articleSlug
	
}
//import styles from './tile.module.scss'


const BoxContacts = ({}) => {
	const data = useStaticQuery(graphql`
			query SupportSiteBoxContacts {
			allContentfulSupportSiteContactSection(filter: {title: {eq: "Box & TV service providers"}}) {
				nodes {
				  title
				  id
				  description {
					childMarkdownRemark {
					  html
					}
				  }
				  contacts {
					... on ContentfulSupportSiteContact {
					  id
					  title
					  logo {
						file {
						  details {
							image {
							  width
							  height
							}
						  }
						  url
						}
					  }
					  number {
						childMarkdownRemark {
						  html
						}
					  }
					  lineDetails {
						childMarkdownRemark {
						  html
						}
					  }
					  website {
						childMarkdownRemark {
						  html
						}
					  }
					}
				  }
				}
			  }
			}
		  `)
		  
 
console.log(data)
	return (
		 <div className={styles.popularArticleContainer}>
		 <p>{data.allContentfulSupportSiteContactSection.nodes[0].title}</p>
		 
	</div>
	)
}


export default BoxContacts


