import React from 'react'
import { Link } from 'gatsby'

import styles from './tile.module.scss'


const NewTile = ({ tileTitle, tileSlug, articles, productSlug, icon, index }) => {
  var tileClass = styles.tile
  if(articles !== undefined && icon !== undefined) {
    tileClass = [styles.tile, styles.hiddenArticles].join(' ')
  }
  else {
    tileClass = [styles.tile, styles.titleOnly].join(' ')

  }
  
return (
  <div className={tileClass}>
<p className={styles.topicTitle}> {articles ? tileTitle : <Link to={`/${productSlug}/${tileSlug}/`}>{tileTitle}</Link>}</p>
{articles && <p className={[styles.topicTitle, styles.animated].join(' ')}>{tileTitle}</p>}

{icon && <div className={styles.largeIcon}><img width={icon.file.details.image.width} height={icon.file.details.image.height} src={icon.file.url} /></div>}
{articles && <ul className={styles.topics}>
{ articles.map((article, index) => {
  let articleKey = productSlug + `-` + article.slug
  
  let slug = `/` + productSlug + `/` + tileSlug + `/` + article.slug + `/`
  return (<li key={articleKey}><Link to={slug}>{article.title}</Link></li>)
})
}
  

</ul>}
{articles && <p className={styles.seeAllButton}><Link className={styles.seeAll} to={`/${productSlug}/${tileSlug}/`}>See all</Link></p>}

  </div>
  );
};
export default NewTile


